import React, { useEffect } from "react";
import Light from "../../Assets/images/light-img.svg";
import Cam from "../../Assets/images/cam.svg";
import AOS from "aos";
import "./Landing.css";
const Landing = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <main className="landing-master">
      <image className="light-left" src={Light} alt="light-image" />
      <div className="container landing-main">
        <div className="landing-left">
          <p className="landing-top">Best Security Services....</p>
          <p className="landing-sub">Safe & Secure Home For Your Family</p>
          <p className="landing-content">
            We also handle commercial security services like Cctv, Biometric
            attendance & solar products for more info contact us
          </p>
          <div>
            <button className="landing-button-left">Contact us</button>
            <button className="landing-button">Get quotes</button>
          </div>
        </div>
        <div data-aos="fade-left" className="landing-right">
          <img className="landing-right-img" src={Cam} alt="drone" />
        </div>
      </div>
    </main>
  );
};

export default Landing;
