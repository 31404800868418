import React from "react";
import Hekto from "../../Assets/images/Hekto.svg";
import Search from "../../Assets/images/search.svg";
import "./Navbar.css";

const Navbar = () => {
  return (
    <nav>
      <div className="container nav-bottom-main">
        <div className="nav-bottom-logo">
          <img src={Hekto} alt="Hekto" />
        </div>
        <div className="nav-bottom">
          <ul>
            <li>Home</li>
            <li>Pages</li>
            <li>Products</li>
            <li>Blog</li>
            <li>Shop</li>
            <li>Contact</li>
          </ul>
        </div>
        <div className="nav-bottom-input">
          <div className="nav-bottom-inputmain">
            <input type="text" />
            <button>
              <img src={Search} alt="search" />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
