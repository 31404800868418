import React, { useEffect } from "react";
import rightArrow from "../../Assets/images/right-arrow.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { cardData } from "../../Constants/Constants";
import "./Services.css";

const Services = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section>
      <div className="container our-services">
        <div className="our-services-main">
          <p className="our-services-title-start">Services</p>
          <p className="our-services-title">
            Our Excellent CCTV Security & other services
          </p>
        </div>
        <div className="our-services-card-main">
          {cardData?.map((data, index) => (
            <div className="our-services-card" data-aos="flip-left">
              <img
                className="our-services-card-icon"
                src={data?.image}
                alt="cctvIcon"
              />
              <div className="our-services-card-inner">
                <span className="our-services-card-title">{data?.title}</span>
                <span className="our-services-card-content">
                  {data?.content}
                  <div className="our-services-card-readMore">
                    <span>Read more </span>
                    <img
                      className="our-services-card-arrow"
                      src={rightArrow}
                      alt="arrow"
                    />
                  </div>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
