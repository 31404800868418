import "./App.css";
import Navbar from "./Pages/Navbar/Navbar";
import Landing from "./Pages/Landing/Landing";
import Services from "./Pages/Services/Services";
import Offer from "./Pages/Offer/Offer";
import Footer from "./Pages/Footer/Footer";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Landing />
      <Services />
      <Offer />
      <Footer />
    </div>
  );
}

export default App;
