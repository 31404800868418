import React from "react";
import Hekto from "../../Assets/images/Hekto.svg";
import "./Footer.css";

const Footer = () => {
  return (
    <section class="main-footer">
      <div class="container footer-master">
        <div class="footer-main">
          <div>
            <div class="footer-content-left">
              <img src={Hekto} alt="hekto" />
            </div>
            <div class="footer-main-input">
              <input type="text" placeholder="Enter Your Email" />
              <button>Sign Up</button>
            </div>
            <div class="footer-contact">
              <p>Contact info</p>
              <p>17 Princess Road, London, Greater London NW1 8JR, UK</p>
            </div>
          </div>
          <div class="footer-main-middle">
            <p class="footer-title">Categories</p>
            <p class="footer-content">Laptops & Computers</p>
            <p class="footer-content">Cameras & Photography</p>
            <p class="footer-content">Smart Phones & Tablets</p>
            <p class="footer-content">Video Games & Consoles</p>
            <p class="footer-content">Waterproof Headphones</p>
          </div>
          <div class="footer-main-middle">
            <p class="footer-title">My Account</p>
            <p class="footer-content">Discount</p>
            <p class="footer-content">Returns</p>
            <p class="footer-content">Orders History</p>
            <p class="footer-content">Order Tracking</p>
            <p class="footer-content">Order Tracking</p>
          </div>
          <div class="footer-main-middle">
            <p class="footer-title">Pages</p>
            <p class="footer-content">Blog</p>
            <p class="footer-content">Browse the Shop</p>
            <p class="footer-content">Pre-Built Pages</p>
            <p class="footer-content">Visual Composer Elements</p>
            <p class="footer-content">Waterproof Headphones</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
