import cctvIcon from "../Assets/images/security-camera.png";
import solarPanel from "../Assets/images/solar-panel.png";
import biometric from "../Assets/images/biometric.png";
import delivery from "../Assets/images/shopex-delivery.svg";
import hands from "../Assets/images/shopex-hands.svg";
import badge from "../Assets/images/shopex-badge.svg";
import support from "../Assets/images/shopex-call.svg";

export const cardData = [
  {
    title: "CCTV services",
    content: "CCTV services for both residential and commercial services",
    image: cctvIcon,
  },
  {
    title: "Solar panels",
    content: "Solar panels for both residential and commercial services",
    image: solarPanel,
  },
  {
    title: "Biometric",
    content: "Biometric services for companies",
    image: biometric,
  },
];

export const offerData = [
  {
    title: "Delivery Assured",
    content: "We'll deliver the products at rite time promised",
    image: delivery,
  },
  {
    title: "Guarantee Assured",
    content: "We're guarantee for your products delivered by us",
    image: hands,
  },
  {
    title: "Products Assured",
    content: "The products we'll deliver will be well known brands",
    image: badge,
  },
  {
    title: "Support Assured",
    content: "We'll deliver 24/7 support to our customers",
    image: support,
  },
];
